<template>
	<div class="app-body has-bottom">
		<div class="home-container scroll-cont">
			<div class="userHeader">
				<div class="bg"><img src="../../assets/images/userheader.png" alt="" class="img-responsive"></div>
				<div class="avatar-box">
					<p>{{userInfo.truename ? userInfo.truename : userInfo.mobile}}</p>
					<p><img :src="userInfo.avatar_img ? userInfo.avatar_img : require('../../assets/images/circle.png')" alt=""></p>
				</div>
				<div class="avatar-name">
					{{userInfo.truename}}
				</div>
			</div>
			<div class="user-cell-group">
				<van-cell title="已绑定手机号"    url="" :value="userInfo.mobile">
					<van-icon name="" slot="icon" class="phone"> <img src="../../assets/images/phone.png" alt=""> </van-icon>
				</van-cell>
				<!-- <van-cell class="mt text-center f16 text-grey" slot="icon" @click="logout">退出登录</van-cell> -->
				
			</div>
		</div>
		<foot-guide></foot-guide>

	</div>
</template>
<script>
import footGuide from '@/components/footer/index'
import { Cell,Icon,Toast  } from 'vant'
export default{
	name:'user',
	components: {
		footGuide,
		[Cell.name]:Cell,
		[Icon .name]:Icon ,
	},
	data() {
		return {
			userInfo: this.$store.state.userInfo,
		}
	},
	mounted() {
		
	},
	methods: {
		
	}
}
</script>
<style lang="scss" scoped>
	 @import '../../assets/css/mixin';
	.userHeader{
		position:relative;
		padding-bottom:0.5rem;
		.avatar-box{
			@include sc(0.5rem,#fff);
			position:absolute;
			bottom:0rem;
			width:50%;
			left:0.6rem;
			text-align:center;
			p{

				margin-bottom:0.1rem;
				img{
					width:2.35rem;
					border-radius:50%;
				}
			}
		}
		.avatar-name{
			position:absolute;
			right:0.4rem;
			bottom:0.4rem;
			@include sc(0.4rem,#66cebc);
			padding:0.2rem 0;
			border-bottom:2px solid #6ecfaf;
		}
	}
	.user-cell-group{
		margin:0.5rem 0;
		img{
			vertical-align: middle;
		}
		.phone{
			width:0.4rem;
			vertical-align:middle;
			padding-right:0.1rem;
			margin-top:0.03rem;
		}
	}
	.user-time{
		padding:0.4rem 0.4rem 0.4rem 0.8rem;
		background:#fff;
	}
	.user-phone{
		padding:0.4rem 0.4rem 0.4rem 0.8rem;
	}
</style>